import React, { useEffect, useState } from "react";
import userModel from "store/userModel";
import networkModel from "store/networkModel";
import nodeModel from "store/nodeModel";
import { observer } from 'mobx-react';

// components

import CardStats from "components/Cards/CardStats.js";

function HeaderStats() {
  useEffect(() => {
    userModel.loadHeader();
  }, [userModel.networks, userModel.nodes, nodeModel.nodes.length, networkModel.networks.length]);

  return (
    <>
      {/* Header */}
      <div className="relative bg-blueGray-200 md:pt-12 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Сети"
                  statTitle={userModel.networks}
                  statLinkText="Все сети"
                  statLink="/admin/networks"
                  statIconName="fa fa-sitemap"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Узлы"
                  statTitle={userModel.nodes}
                  statLinkText="Все узлы"
                  statLink="/admin/nodes"
                  statIconName="fa fa-database"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Счета"
                  statTitle={userModel.invoices}
                  statLinkText="Все счета"
                  statLink="/admin/invoices"
                  statIconName="fa fa-file-invoice-dollar"
                  statIconColor="bg-green-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Поддержка"
                  statTitle={''}
                  statLinkText="Отправить сообщение"
                  statLink="mailto:info@izzz.io"
                  statIconName="fa fa-envelope"
                  statIconColor="bg-blue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(HeaderStats);