import React, { useState, useEffect } from "react";
import Modal from "components/Modals/Modal";
import nodeModel from "store/nodeModel";
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';
import {useHistory} from "react-router";


function CardNodeList(props) {
    const history = useHistory();

    const nodes = nodeModel.nodes;
    const nodesColumn = nodeModel.nodesColumn;

    useEffect(() => {
        nodeModel.loadNodeList();
    }, [nodes.length]);

    function selectItemAndRedirect(item) {
        history.push(`/admin/nodes/detail/${item.id}/`);
    }

    function nodeItem(item) {
        function turnNodeHandler() {
            nodeModel.turnStatus(item.id, !item.is_active)
            .then(data => {
                item.is_active = data.is_active;
            })
        }

        return (
            <tr
                key={item.id}
                className='node-item'
                onClick={() => selectItemAndRedirect(item)}
            >
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {item.id}
                </th>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {item.network}
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item.is_active ?
                        <i className="fas fa-stop text-red-500 mr-4 turn-node-btn" onClick={turnNodeHandler} /> :
                        <i className="fas fa-play text-emerald-500 mr-4 turn-node-btn" onClick={turnNodeHandler} />
                    }
                </td>
            </tr>
        )
    }


    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-2 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-base text-blueGray-700">
                                <i className="fas fa-database mr-3" ></i>
                                Узлы
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                {
                                nodesColumn.map(title => (
                                    <th
                                        key={title}
                                        className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                        {title}
                                    </th>
                                ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {nodes.map(item => nodeItem(item))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default observer(CardNodeList);
