import { makeObservable, observable, action } from "mobx";
import API from 'api';

class ContractModel {
    contractTemplates = [];
    clientContracts = [];

    contractColumn = ['Адрес контракта', 'Контракт', 'Статус', 'Сеть'];

    constructor() {
        makeObservable(this, {
            contractTemplates: observable,
            clientContracts: observable,

            loadContractTemplates: action,
        });
    }

    get firstContractTemplate() {
        return this.contractTemplates[0];
    }

    getContractById(contractId) {
        return this.contractTemplates.find(_ => _.id == contractId);
    }

    loadContractTemplates() {
        return new Promise((resolve, reject) => {
            API.get(`/contract/template/list/`)
            .then(response => {
                const list = response.data;
                this.contractTemplates = list;
                resolve(list);
            })
            .catch(reject)
        })
    }

    loadClientContracts(networkId) {
        return new Promise((resolve, reject) => {
            const search = new URLSearchParams();
            if (networkId) {
                search.append('network_id', networkId);
            }

            API.get(`/contract/list/?${search.toString()}`)
            .then(response => {
                const list = response.data;
                this.clientContracts = list;
                resolve(list);
            })
            .catch(reject)
        })
    }

    deployContract(networkId, title, code, isWalletRequest) {
        return new Promise((resolve, reject) => {
            const data = {
                network_id: networkId,
                title,
                code,
                is_wallet_request: isWalletRequest
            }

            API.post(`/contract/deploy/`, data)
            .then(response => {
                const isDeploy = response.data.is_deployed;
                resolve(isDeploy);
            })
            .catch(reject)
        })
    }

    getContractPreview(contractId, items) {
        return new Promise((resolve, reject) => {
            const data = {
                contract_id: contractId,
                items,
            }

            API.post('/contract/template/preview/', data)
                .then(response => {
                    const data = response.data.contract_code;
                    resolve(data);
                })
                .catch(reject)
        })
    }
}

const contractModel = new ContractModel();

export default contractModel;
