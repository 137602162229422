import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Networks from "../views/admin/Networks";
import Nodes from "../views/admin/Nodes";
import Contracts from "../views/admin/Contracts";
import Settings from "../views/admin/Settings.js";
import Invoice from "../views/admin/Invoice.js";
import NodeDetail from "../views/admin/NodeDetail";
import NetworkDetail from "../views/admin/NetworkDetail";
import FormGenerator from "../views/admin/FormGenerator";

export default function Admin(props) {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard">
              <Dashboard />
            </Route>

            <Route path="/admin/networks" exact>
              <Networks />
            </Route>
            <Route path="/admin/networks/detail/:networkId">
              <NetworkDetail />
            </Route>

            <Route path="/admin/nodes" exact>
              <Nodes />
            </Route>
            <Route path="/admin/nodes/detail/:nodeId">
              <NodeDetail />
            </Route>

            <Route path="/admin/contracts">
              <Contracts />
            </Route>
            <Route path="/admin/settings">
              <Settings />
            </Route>
            <Route path="/admin/invoices">
              <Invoice />
            </Route>
            <Route path="/admin/form">
              <FormGenerator />
            </Route>

            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
