import React, { useEffect, useState } from "react";
import networkModel from "store/networkModel";
import nodeModel from "store/nodeModel";
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';

function CardNodeDetailStats(props) {
    const nodeId = props.nodeId;

    const items = nodeModel.detailStats;

    useEffect(() => {
        nodeModel.loadDetailStats(nodeId);
    }, [nodeId]);

    
    function nodesItem(item, index) {
        return (
            <tr key={item.id}
                className='node-item'
            >
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {index + 1}
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <p>{new Date(item.date_create).toISOString()}</p>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <p>{item.is_online ? 'Включен' : 'Выключен'}</p>
                </td>
            </tr>
        )
    }


    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-2 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-base text-blueGray-700">
                                <i className="fas fa-database mr-3" ></i>
                                Статистика работы
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto h-screen">
                    {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <tbody>
                            {items?.map((item, index) => nodesItem(item, index))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default observer(CardNodeDetailStats);