import { makeObservable, observable, action } from "mobx"
import API from 'api';
import userModel from './userModel';

class AuthModel {

    constructor() {
        makeObservable(this, {
            register: action,
            login: action,
        });
    }

    register(login, password, firstName, lastName) {
        return new Promise(resolve => {
            API.post('/account/register/', { login, password, first_name: firstName, last_name: lastName })
            .then(response => {
                window.localStorage.setItem('userLogin', login);

                resolve(this.login(login, password));
            })
        });
    }

    login(login, password) {
        return new Promise(resolve => {
            API.post('/account/token/', { login, password })
            .then(response => {
                const { data } = response;
                
                const accessToken = data.access;
                const refreshToken = data.refresh;
                
                userModel.accessToken = accessToken;
                userModel.refreshToken = refreshToken;
                userModel.login = login;

                resolve({ accessToken, refreshToken });
            })
        })
    }
}

const authModel = new AuthModel();

export default authModel;