import React, { useState, useEffect } from "react";
import Modal from "components/Modals/Modal";
import networkModel from "store/networkModel";
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';
import {useHistory} from "react-router";

// components

function CardNetworksList(props) {
  const limit = props.limit;
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [showAddNetworkModal, SetShowAddNetworkModal] = useState(false);
  const [networkTitle, setNetworkTitle] = useState('');
  const [networkRPCPassword, setNetworkRPCPassword] = useState('');
  const [networkName, setNetworkName] = useState('');
  const [networkConsensus, setNetworkConsensus] = useState('');
  const [networkAlgoritm, setNetworkAlgoritm] = useState('');
  const [networkRate, setNetworkRate] = useState('');
  const [networkPrivate, setNetworkPrivate] = useState(true);

  const networks = networkModel.networks;
  const networksColumn = networkModel.networksColumn;
  const algorithms = networkModel.algorithms;
  const consensuses = networkModel.consensuses;
  const rates = networkModel.rates;

  useEffect(() => {
    networkModel.loadAlgoritm();
    networkModel.loadConsensus();
    networkModel.loadRates();
  }, []);

  useEffect(() => {
    networkModel.getNetworkList(limit);
  }, [limit]);

  useEffect(() => {
    setNetworkConsensus(networkModel.firstConsensus?.id);
  }, [networkModel.firstConsensus?.id]);

  useEffect(() => {
    setNetworkAlgoritm(networkModel.firstAlgoritm?.id);
  }, [networkModel.firstAlgoritm?.id]);

  function setAlgoritmAndConsensus(algoritmId) {
    const [algoritm, consensus] = networkModel.getAlgoritmAndConsensusById(algoritmId);
    
    setNetworkAlgoritm(algoritm.id);
    if (consensus) {
      setNetworkConsensus(consensus.id);
    }
  }

  function setConsensusAndAlgoritm(consensusId) {
    const [algoritm, consensus] = networkModel.getConsensusAndAlgoritmById(consensusId);

    setNetworkConsensus(consensus.id);
    if (algoritm) {
      setNetworkAlgoritm(algoritm.id);
    }
  }

  useEffect(() => {
    setNetworkRate(networkModel.firstRate?.id);
  }, [networkModel.firstRate?.id]);

  function optionsRateTemplate(items) {
    return items.map((item, index) => (<option key={item.id} value={item.id} title={item.title}>{item.title}</option>))
  }

  function optionsTemplate(items) {
    return items.map((item, index) => (<option key={item.id} value={item.id} title={item.plugin}>{item.title}</option>))
  }

  function handleCreateNetwork() {
    networkModel.createNetwork(networkTitle, networkName, networkConsensus, networkAlgoritm, networkPrivate, networkRate)
    .then(item => {
      setShowModal(false);
      toast.success(`${networkTitle} успешно создана!`);

      if (item.is_private) {
        setNetworkRPCPassword(item.rpc_password);
        SetShowAddNetworkModal(true);
      }
    });
  }

  function selectItemAndRedirect(item) {
    history.push(`/admin/networks/detail/${item.id}/`);
  }

  function networkItem(item) {
    return (
      <tr
        key={item.id}
        className='network-item'
        onClick={() => selectItemAndRedirect(item)}
      >
        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
          {item.is_active ? <i className="fas fa-play text-emerald-500 mr-4" /> : <i className="fas fa-stop text-red-500 mr-4" />}
          {item.title}
        </th>
        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
          {item.is_private ? "Приватная сеть" : "Публичная сеть"}
        </th>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {item.rate_title}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {item.consensus_title}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {item.algorithm_title}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {item.nodes_count}
        </td>
      </tr>
    )
  }

  function clipboardRPCPassword() {
    navigator.clipboard.writeText(networkRPCPassword)
    .then(res => {
      toast.success(`Скопировано!`);
    })
  }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-2 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                <i className="fas fa-network-wired mr-3" ></i>
                Сети
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-green-500 text-white active:bg-green-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                data-toggle="modal" data-target="#exampleModal"
                onClick={() => setShowModal(true)}
              >
                Добавить сеть
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {
                  networksColumn.map(title => (
                    <th
                      key={title}
                      className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      {title}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {networks.map(item => networkItem(item))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal ?
        <Modal
          onRequestClose={() => setShowModal(false)}
          closeOnBackgroundClick={false}
        >
          <h3 className="modal__title">Создание блокчейн-сети</h3>
          <div className='row'>
          <div className='col-12'>
              <label for="network-title" className="form-label">Название сети</label>
              <div className="input-group mb-3">
                <input type="text" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full" 
                id="network-title" 
                value={networkTitle}
                onChange={(e) => setNetworkTitle(e.target.value)}/>
              </div>
            </div>

            <div className='col-12'>
              <label for="network-name" className="form-label">Название сети (лат. буквами)</label>
              <div className="input-group mb-3">
                <input type="text" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full" 
                id="network-name" 
                value={networkName} 
                onChange={(e) => setNetworkName(e.target.value)}/>
              </div>
            </div>

            <div className='col-12'>
              <label for="network-rate" className="form-label">Тариф</label>
              <div className="input-group mb-3">
                <select defaultValue={networkRate} value={networkRate} className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full" 
                id="network-consensus" 
                onChange={(e) => setNetworkRate(e.target.value)}>
                  {
                    optionsRateTemplate(rates)
                  }
                </select>
              </div>
            </div>

            <div className='col-12'>
              <label for="network-consensus" className="form-label">Консенсус</label>
              <div className="input-group mb-3">
                <select defaultValue={networkConsensus} value={networkConsensus} className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full" 
                id="network-consensus"
                onChange={(e) => setConsensusAndAlgoritm(e.target.value)}>
                  {
                    optionsTemplate(consensuses)
                  }
                </select>
              </div>
            </div>

            <div className='col-12'>
              <label for="network-algoritm" className="form-label">Алгоритм</label>
              <div className="input-group mb-3">
                <select defaultValue={networkAlgoritm} value={networkAlgoritm} className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full" 
                id="network-algoritm" 
                onChange={(e) => setAlgoritmAndConsensus(e.target.value)}>
                  {
                    optionsTemplate(algorithms)
                  }
                </select>
              </div>
            </div>

            <div className='col-6 flex justify-around'>
              <div>
                <input type="radio" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline" 
                id="network-private-net" 
                checked={networkPrivate} 
                value={networkPrivate} 
                onChange={(e) => setNetworkPrivate(e.target.checked)} />
                <label for="network-private-net" className="form-label ml-1">Приватная сеть</label>
              </div>
              <div>
                <input type="radio" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline" 
                id="network-public-net" 
                checked={!networkPrivate} 
                value={!networkPrivate} 
                onChange={(e) => setNetworkPrivate(!e.target.checked)} />
                <label for="network-public-net" className="form-label ml-1">Публичная сеть</label>
              </div>
            </div>
          </div>

          <div className='flex justify-center mt-5'>
            <button
              className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal(false)}
            >
              Закрыть
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => handleCreateNetwork()}
            >
              Создать
            </button>
          </div>

        </Modal>
      : null}
      { showAddNetworkModal ? 
        <Modal
          onRequestClose={() => SetShowAddNetworkModal(false)}
          closeOnBackgroundClick={false}
        >
            <h3 className="modal__title">Создание блокчейн-сети</h3>
            <div className='row'>
              <div className='col-12'>
                  <label for="network-title" className="form-label">Скопируйте и сохраните пароль для вашей приватной сети</label>
                  <div className="input-group mb-3">
                    <input type="text" 
                      className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full" 
                      id="network-rpc-password" 
                      value={networkRPCPassword}
                      readOnly={true}
                      onClick={(e) => clipboardRPCPassword()}
                    />
                  </div>
                </div>
                <div className='flex justify-center mt-5'>
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => SetShowAddNetworkModal(false)}
                >
                  Ок
                </button>
              </div>
            </div>
        </Modal>
      : null}
    </>
  );
}

export default observer(CardNetworksList);
