import React, { useEffect, useState } from "react";
import networkModel from "store/networkModel";
import nodeModel from "store/nodeModel";
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';

function CardNodeDetail(props) {
    const nodeId = props.nodeId;

    const item = nodeModel.detail;

    useEffect(() => {
        nodeModel.loadDetail(nodeId);
    }, [nodeId]);


    function turnNodeHandler() {
        nodeModel.turnStatus(item.id, !item.is_active)
        .then(data => {
            item.is_active = data.is_active;
        })
    }


    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-2 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-base text-blueGray-700">
                                <i className="fas fa-network-wired mr-3" ></i>
                                {item.network_name}
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="block w-full overflow-x-auto">
                    {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <tbody>
                        <tr>
                            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                ID ноды
                            </th>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {item.id}
                            </td>
                        </tr>
                        <tr>
                            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                Название сети
                            </th>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {item.network_name}
                            </td>
                        </tr>
                        <tr>
                            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                Локация ноды
                            </th>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {item.server_location}
                            </td>
                        </tr>
                        <tr>
                            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                Домен
                            </th>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {item.origin}
                            </td>
                        </tr>
                        <tr>
                            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                Статус
                            </th>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            {item.is_active ? 
                                <i className="fas fa-stop text-red-500 mr-4 turn-node-btn" onClick={turnNodeHandler} /> : 
                                <i className="fas fa-play text-emerald-500 mr-4 turn-node-btn" onClick={turnNodeHandler} /> 
                            }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default observer(CardNodeDetail);