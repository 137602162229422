import Axios from 'axios';

const API = Axios.create({
    baseURL: 'https://api.construct.izzz.io/api',
    responseType: 'json',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        
        ...localStorage.getItem('accessToken') ? {'Authorization': `Bearer ${localStorage.getItem('accessToken')}`} : null
    }
});

API.interceptors.response
.use(
    response => response, 
    error => {
        if (error.response.status === 401 && window.location.pathname !== '/auth/login') {
            window.location.href = '/auth/login';
        }

        return error;
    }
);

export default API;