import React, {useCallback, useEffect, useRef, useState} from "react";
import { v4 as uuidv4 } from 'uuid';
import JSONInput from "react-json-editor-ajrm";

import useResizeTwoBlocks from "../../hooks/useResizeTwoBlocks";

import Input from "../../components/Input/Input";
import MultipleInput from "../../components/MultipleInput/MultipleInput";
import {inputTypes} from "../../constants";
import Button from "../../components/Button/Button";

import contractModel from "./../../store/contractModel";
import CodeEditor from '@uiw/react-textarea-code-editor';
import {useHistory, useLocation} from "react-router";

const checkboxType = "checkbox";

export default function FormGenerator(props) {
    const location = useLocation();

    const state = location?.state;
    const contractId = state?.contractId;
    const isWalletRequest = state?.isWalletRequest;
    const json = state?.code ? JSON.parse(state?.code) : [];

    const history = useHistory();

    const [inputs, setInputs] = useState(null);
    const [inputErrors, setInputErrors] = useState([]);
    const [showEditor, setShowEditor] = useState(true);

    const [finalJSON, setFinalJSON] = useState(json);
    const [isEditorJSONError, setIsEditorJSONError] = useState(null);
    const [editingJSON, setEditingJSON] = useState(json);

    const [serverCode, setServerCode] = useState(null);
    const [isDeployingCode, setIsDeployingCode] = useState(false);

    const mainBlock = useRef(null);
    const formBlock = useRef(null);
    const jsonInput = useRef(null);

    const { leftSideWidth, rightSideWidth, borderPosition, onMouseDownHandler} = useResizeTwoBlocks(mainBlock)

    const getDefaultInputError = useCallback((id) => ({
        id,
        required: false,
        maxLength: false,
    }), [])

    const getInputData = useCallback(({ placeholder, dafault, title, max_length, min_length, type, required }) => {
        const id = uuidv4();

        setInputErrors((prev) => [...prev, getDefaultInputError(id)]);

        return {
            id,
            title,
            name: placeholder,
            value: dafault || "",
            max_length,
            min_length,
            required,
            type,
        }
    }, [])

    useEffect(() => {
        const inputs = finalJSON.map(({ data, ...other}) => {
            const { type, min_length } = other;
            const isMultiple = type === inputTypes.multiple;

            return {
                ...getInputData(other),
                ...(isMultiple && { data: [
                        ...new Array(min_length || 1).fill("").map(() => data.map((data) => ({
                            ...getInputData(data),
                        })))
                    ] })
            }
        })
        setInputs(inputs)
    }, [finalJSON])

    function onChangeHandler(e, id) {
        const isCheckbox = e.target.type === checkboxType;
        const value = isCheckbox ? e.target.checked : e.target.value;

        setInputs((prev) => {
            return prev.map((input) => {
                if(input.id === id) {
                    input.value = value;
                }
                return input;
            })
        })
    }

    function onChangeMultipleData(e, id, parentName, commonIndex) {
        const isCheckbox = e.target.type === checkboxType;
        const value = isCheckbox ? e.target.checked : e.target.value;

        setInputs((prev) => {
            return prev.map((inputGroups) => {
                if(inputGroups.name === parentName) {
                    return {
                        ...inputGroups,
                        data: inputGroups.data.map((inputGroup, index) => {
                            if (index === +commonIndex) {
                                return inputGroup.map((input) => {
                                    if (input.id === id) {
                                        input.value = value;
                                    }
                                    return input
                                })
                            }
                            return inputGroup;
                        })
                    }
                }
                return inputGroups;
            })
        })
    }

    function addInputsGroup(parentName) {

        setInputs((prev) => {
            return prev.map((inputGroups) => {
                if(inputGroups.name === parentName) {
                    const reference = inputGroups?.data[0] || [finalJSON.find(({ placeholder }) => placeholder === parentName).data[0]]

                    return {
                        ...inputGroups,
                        data: [
                            ...inputGroups.data,
                            [...reference]?.map((input) => {
                                const id = uuidv4();

                                setInputErrors([...inputErrors, getDefaultInputError(id)])

                                return {
                                    ...input,
                                    value: "",
                                    id,
                                }
                            })
                        ]
                    }
                }
                return inputGroups;
            })
        })
    }

    function removeInputsGroup(parentName, indexGroup) {
        setInputs((prev) => {
            return prev.map((inputGroups) => {
                if(inputGroups.name === parentName) {
                    return {
                        ...inputGroups,
                        data: inputGroups.data.filter((_, index) => index !== indexGroup ),
                    }
                }
                return inputGroups;
            })
        })
    }

    const formSubmitHandler = async () => {
        if(inputErrors.some(({ required, maxLength }) => required || maxLength)) return;

        const getDataObject = (name, value) => ({
            placeholder: name,
            value
        });

        const data = inputs.map(({ name, value, type, data }) => {
            if(type === inputTypes.multiple) {
                return {
                    placeholder: name,
                    value: data.map((inputsGroup) => inputsGroup.map(({ name, value }) => {
                        return getDataObject(name, value)
                    }))
                }
            }
            return getDataObject(name, value)
        });

        const code = await contractModel.getContractPreview(contractId, data);
        setServerCode(code);
    }

    const userJsonDataChangeHandler = useCallback((data) => {
        const { json, error } = data;

        setIsEditorJSONError(!!error);
        setEditingJSON(json);
    }, [setIsEditorJSONError, setEditingJSON]);

    const applyUserJson = useCallback(() => {
        if(isEditorJSONError) return;

        const data = JSON.parse(editingJSON);

        setFinalJSON(data);
    }, [isEditorJSONError, setFinalJSON, editingJSON]);

    const changeShowingEditor = useCallback(() => {
        setShowEditor(!showEditor);
    }, [setShowEditor, showEditor]);

    const checkJSONHandler = useCallback(() => {
        setIsEditorJSONError(null);
        jsonInput.current?.update();
    }, [jsonInput.current]);

    const onResetErrorHandler = useCallback(() => setIsEditorJSONError(null), [setIsEditorJSONError]);

    useEffect(() => void (jsonInput.current.onPaste = null), [])

    const onChangeJSCode = (e) => {
        const { value } = e.target;
        setServerCode(value);
    }

    const onSubmitJSCode = useCallback(async () => {
        const { networkId, title } = location.state;
        setIsDeployingCode(true);
        await contractModel.deployContract(networkId, title, serverCode, isWalletRequest);
        history.push(`/admin/networks/detail/${networkId}`);
    }, [contractModel.deployContract, location.state, history, serverCode]);

    return(
        <div className="flex mt-20 flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 lg:px-10">
            { serverCode ? (
                <>
                    <CodeEditor
                        value={serverCode}
                        language="js"
                        onChange={onChangeJSCode}
                        padding={15}
                    />
                    <Button className="mt-2" onClick={onSubmitJSCode} disabled={isDeployingCode} isLoading={isDeployingCode}>
                        Деплой
                    </Button>
                </>
            ) : (
                <>
                    <div className="mb-4 mt-8">
                        <Button onClick={changeShowingEditor} fullWidth={false}>
                            {`${showEditor ? "Скрыть" : "Показать"} редактор`}
                        </Button>
                    </div>

                    <div className="flex py-4 pt-0 align-stretch justify-between relative" ref={mainBlock}>
                        <form
                        className="w-full"
                        {...(showEditor && { style:{ width: leftSideWidth }})}
                        ref={formBlock}
                        >
                            {inputs?.map((data) => {
                                return data.type === inputTypes.multiple ?
                                    <MultipleInput
                                        {...data}
                                        key={data.id}
                                        onChangeHandler={onChangeMultipleData}
                                        errors={inputErrors}
                                        setErrors={setInputErrors}
                                        addInputsGroup={addInputsGroup}
                                        removeInputsGroup={removeInputsGroup}
                                    />
                                    :
                                    <Input
                                        {...data}
                                        onChangeHandler={onChangeHandler}
                                        key={data.id}
                                        errors={inputErrors}
                                        setErrors={setInputErrors}
                                    />
                            })}

                            <div className="text-center mt-6">
                                <Button type="button" onClick={formSubmitHandler}>
                                    Отправить
                                </Button>
                            </div>
                        </form>
                        <div
                            className={`${showEditor ? "" : "hidden"} -ml-1 w-2 bg-blueGray-800 cursor-col-resize absolute`}
                                style={{
                                height: `${formBlock.current?.clientHeight}px`,
                                cursor: "col-resize",
                                left: borderPosition ? `${borderPosition}px` : "50%",
                            }}
                            onMouseDown={onMouseDownHandler}
                        />
                        <div
                            className={`${showEditor ? "" : "hidden"}`}
                            style={{ width: rightSideWidth }}
                            onKeyPress={onResetErrorHandler}
                        >
                            <JSONInput
                                ref={jsonInput}
                                width="100%"
                                placeholder={finalJSON}
                                onChange={userJsonDataChangeHandler}
                                onKeyPressUpdate={false}
                            />
                            <Button className="mt-2" onClick={isEditorJSONError === false ? applyUserJson : checkJSONHandler}>
                                {isEditorJSONError === false ? "Применить" : "Проверить"}
                            </Button>
                            {isEditorJSONError && <label className="block text-red-600 text-xs font-bold">Ошибка в JSON объекте</label>}
                        </div>
                    </div>
                </>
            ) }
        </div>
    )
}
