import React from "react";

import CarNodeDetail from "../../components/Cards/CarNodeDetail";
import CarNodeDetailStats from "../../components/Cards/CarNodeDetailStats";
import {useHistory, useParams} from "react-router";

export default function NetworkDetail(props) {
    const history = useHistory()

    const { nodeId } = useParams()

    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
                    <CarNodeDetail history={history} nodeId={nodeId} />
                </div>
                <div className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
                    <CarNodeDetailStats history={history} nodeId={nodeId} />
                </div>

            </div>
        </>
    );
}
