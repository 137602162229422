import React, { useEffect, useState } from "react";
import Modal from "components/Modals/Modal";
import contractModel from "store/contractModel";
import nodeModel from "store/nodeModel";
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';
import {useHistory} from "react-router";

// components

function CardNetworkContracts(props) {
    const networkId = props.networkId;
    const history = useHistory();

    const [showModal, setShowModal] = useState(false);
    const [contractTemplate, setContractTemplate] = useState('');

    const clientContracts = contractModel.clientContracts;
    const contractTemplates = contractModel.contractTemplates;


    useEffect(() => {
        contractModel.loadContractTemplates();
    }, [contractModel.firstContractTemplate?.id]);

    useEffect(() => {
        setContractTemplate(contractModel.firstContractTemplate?.id);
    }, [contractModel.firstContractTemplate?.id]);

    useEffect(() => {
        contractModel.loadClientContracts(networkId);
    }, [networkId]);


    function optionsTemplate(items) {
        return items.map((item, index) => (<option key={item.id} value={item.id} title={item.title}>{item.title}</option>))
    }

    function handleCreateContractByTemplate() {
        const contract = contractModel.getContractById(contractTemplate);

        history.push({
            pathname: '/admin/form',
            state: {
                networkId,
                contractId: contract.id,
                title: contract.title,
                isWalletRequest: contract.is_wallet_request,
                code: JSON.stringify(contract.data)
            },
        });
    }

    function renderClientConracts(contractsData) {
        function handleOpenContract(item) {

            if (item.is_wallet_request) {
                const node = nodeModel.onlineNode;
                const walletUrl = `${node.origin}/wallet/?contractAddress=${item.contract_address}`
                window.open(walletUrl, '_blank');
            }
        }

        return contractsData.map(contractData => (
            <tr className="contract-item">
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {contractData.contract_address}
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {contractData.title}
                </td>
                {contractData.is_wallet_request ? 
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" onClick={() => handleOpenContract(contractData)}>
                        {/* <i className="fas fa-check-circle text-emerald-500 mr-4"></i> */}
                        Кошелек
                    </td>
                    : 
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {/* <i className="fas fa-check-circle text-emerald-500 mr-4"></i> */}
                        
                    </td>
                }
                {contractData.is_deployed ?
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <i className="fas fa-check-circle text-emerald-500 mr-4"></i>
                        В сети
                    </td>
                    :
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <i className="fas fa-upload text-lightBlue-500 mr-4"></i>
                        Загрузить
                    </td>
                }
                {contractData.is_deployed ?
                    <td>
                        <i className="fas fa-info-circle text-blueGray-300 mr-4"></i>
                    </td>
                    :
                    <td>
                        <i className="fas fa-pen text-blueGray-300 mr-4"></i>
                    </td>
                }
            </tr>
        ))
    }


    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-2 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-base text-blueGray-700">
                                <i className="fas fa-file-code mr-3" ></i>
                                Смарт-Контракты сети
                            </h3>
                        </div>
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                            <button
                                className="bg-emerald-500 text-white active:bg-emerald-500 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowModal(true)}
                            >
                                Добавить контракт
                            </button>
                        </div>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <tbody>
                            {renderClientConracts(clientContracts)}
                        </tbody>
                    </table>
                </div>
            </div>
            {showModal ?
                <Modal
                    onRequestClose={() => setShowModal(false)}
                    closeOnBackgroundClick={false}
                >
                <h3 className="modal__title">Создание контракта</h3>
                <div className='row'>
                    <div className='col-12'>
                        <label for="contract-template" className="form-label">Тип смарт-контракта</label>
                        <div className="input-group">
                            <select
                                defaultValue={contractTemplate}
                                value={contractTemplate}
                                className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"
                                id="contract-template"
                                onChange={(e) => setContractTemplate(e.target.value)}
                            >
                            {
                                optionsTemplate(contractTemplates)
                            }
                            </select>
                        </div>
                    </div>
                </div>


                <div className='flex justify-center mt-5'>
                    <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                    >
                    Закрыть
                    </button>

                    <button
                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => handleCreateContractByTemplate()}
                    >
                    К редактору
                    </button>
                </div>

                </Modal>
            : null}
        </>
    );
}


export default observer(CardNetworkContracts);
