import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import FooterSmall from "components/Footers/FooterSmall.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";

// views

import Login from "views/auth/Login.js";
import Forgot from "views/auth/Forgot.js";
import ForgotChange from "views/auth/ForgotChange.js";
import Register from "views/auth/Register.js";

export default function Auth() {
  return (
    <>
      <IndexNavbar fixed />

      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          />
          <Switch>

            <Route path="/auth/login">
              <Login />
            </Route>

            <Route path="/auth/forgot" exact>
              <Forgot />
            </Route>

            <Route path='/auth/forgot/change' exact>
              <ForgotChange />
            </Route>

            <Route path="/auth/register">
              <Register />
            </Route>

            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
