import React from "react";

import CardNetworksList from "components/Cards/CardNetworksList.js";
import CardInvoicesList from "components/Cards/CardInvoicesList.js";
import {useHistory} from "react-router";

export default function Dashboard(props) {
  const history = useHistory()

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
          <CardNetworksList limit={4} history={history}/>
        </div>
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
          <CardInvoicesList limit={4} history={history}/>
        </div>
      </div>
    </>
  );
}
