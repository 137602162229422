import React from "react";

import CardNetworksList from "components/Cards/CardNetworksList.js";
import {useHistory} from "react-router";

export default function Networks(props) {
    const history = useHistory()

    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
                    <CardNetworksList history={history}/>
                </div>
            </div>
        </>
    );
}
