import { makeObservable, observable, action } from "mobx";
import API from 'api';
import nodeModel from "./nodeModel";

class NetworkModel {
    networks = [];
    algorithms = [];
    rates = [];
    consensuses = [];
    detail = {};

    networksColumn = ['Сеть', 'Тип', 'Тариф', 'Консенсус', 'Алгоритм', 'Узлы'];

    constructor() {
        makeObservable(this, {
            networks: observable,
            algorithms: observable,
            rates: observable,
            consensuses: observable,
            detail: observable,

            loadConsensus: action,
            loadAlgoritm: action,
            loadRates: action,
            updateTitle: action,
            createNetwork: action,
            getNetworkList: action,
            getAlgoritmAndConsensusById: action,
        });

        // this.getNetworkList();
        // this.loadAlgoritm();
        // this.loadConsensus();
    }

    get firstConsensus() {
        return this.consensuses[0];
    }

    get firstAlgoritm() {
        return this.algorithms[0];
    }

    get firstRate() {
        return this.rates[0];
    }

    getAlgoritmAndConsensusById(algoritmId) {
        const algoritm = this.algorithms.find(_ => _.id === algoritmId);
        let consensus = null;
        
        if (algoritm?.plugin === 'GOST') {
            consensus = this.consensuses.find(_ => _.plugin === 'PoA');
        } else if (algoritm?.plugin === 'SHA256') {
            consensus = this.consensuses.find(_ => _.plugin === 'DLCPoA')
        }

        return [algoritm, consensus];
    }

    getConsensusAndAlgoritmById(consensusId) {
        const consensus = this.consensuses.find(_ => _.id === consensusId);
        let algorithm = null;

        if (consensus?.plugin === 'PoA') {
            algorithm = this.algorithms.find(_ => _.plugin === 'GOST')
        } else if (consensus?.plugin === 'DLCPoA') {
            algorithm = this.algorithms.find(_ => _.plugin === 'SHA256');
        }

        return [algorithm, consensus]
    }

    loadDetail(networkId) {
        return new Promise((resolve, reject) => {
            API.get(`/network/detail/${networkId}/`)
            .then(response => {
                const {data} = response;
                this.detail = data;
                nodeModel.nodes = data.nodes;
                resolve(data);
            })
            .catch(reject)
        })
    }

    loadConsensus() {
        return new Promise((resolve, reject) => {
            if (this.consensuses.length !== 0) {
                return resolve(undefined);
            }
            
            API.get('/network/consensus/list/')
            .then(response => {
                const list = response.data;
                this.consensuses = list;
                resolve(list);
            })
            .catch(reject)
        })
    }

    loadAlgoritm() {
        return new Promise((resolve, reject) => {
            if (this.algorithms.length !== 0) {
                return resolve(undefined);
            }

            API.get('/network/algorithm/list/')
            .then(response => {
                const list = response.data;
                this.algorithms = list;
                resolve(list);
            })
            .catch(reject)
        })
    }

    loadRates() {
        return new Promise((resolve, reject) => {
            if (this.rates.length !== 0) {
                return resolve(undefined);
            }

            API.get('/network/rate/list/')
            .then(response => {
                const list = response.data;
                this.rates = list;
                resolve(list);
            })
            .catch(reject)
        })
    }

    updateTitle(networkId, title) {
        return new Promise((resolve, reject) => {
            API.put(`/network/${networkId}/title/`, { title })
            .then(response => {
                const netData = response.data;
                resolve(netData)
            })
            .catch(reject);
        })
    }

    createNetwork(title, name, consensus, algorithm, isPrivate, rate) {
        return new Promise((resolve, reject) => {
            API.post('/network/create/', { title, network_name: name, consensus, algorithm, is_private: isPrivate, rate })
            .then(response => {
                const item = response.data;
                this.networks.push(item);
                resolve(item);
            })
            .catch(reject)
        })
    }

    getNetworkList(limit) {
        return new Promise((resolve, reject) => {
            const search = new URLSearchParams();
            if (limit) {
                search.append('limit', limit);
            }
            const searchStr = search.toString();
            API.get(`/network/list/${searchStr ? `?${searchStr}` : ''}`)
            .then(response => {
                const list = response.data;
                this.networks = list;
                resolve(list);
            })
            .catch(reject)
        });
    }
}

const networkModel = new NetworkModel();

export default networkModel;