import { makeObservable, observable, action } from "mobx"
import API from 'api';


class UserrModel {
    login = '';
    accessToken = '';
    refreshToken = '';

    user = {};

    networks= 0;
    nodes = 0;
    invoices = 0;

    constructor() {
        makeObservable(this, {
            login: observable,
            accessToken: observable,
            refreshToken: observable,

            networks: observable,
            nodes: observable,
            invoices: observable,

            user: observable,

            loadHeader: action,
            loadUserInfo: action,
        });
    }

    loadHeader() {
        return new Promise((resolve, reject) => {
            API.get('/account/headers/')
            .then(res => {
                console.log('res.data', res.data);
                this.networks = res?.data?.networks || this.networks;
                this.nodes = res?.data?.nodes || this.nodes;
                this.invoices = res?.data?.invoices || this.invoices;

                resolve(res?.data);
            })
            .catch(reject)
        });
    }

    loadUserInfo() {
        return new Promise((resolve, reject) => {
            API.get('/account/info/')
            .then(res => {
                this.user = res.data;

                resolve(res.data);
            })
            .catch(reject)
        });
    }

    updateProfileData(data) {
        return new Promise((resolve, reject) => {
            API.put('/account/info/', data)
            .then(res => {
                this.user = res.data;

                resolve(res.data);
            })
            .catch(reject)
        });
    }
}

const userModel = new UserrModel();

export default userModel;
