import React, {memo} from "react";
import Input from "../Input/Input";
import Button from "../Button/Button";
import {inputTypes} from "../../constants";

const MultipleInput = ({
   title,
   onChangeHandler,
   max_length,
   name: parentName,
   data,
   errors,
   setErrors,
   addInputsGroup,
   removeInputsGroup,
   min_length,
}) => {
    const showAddBtn = max_length < 1 || data?.length < max_length
    const showRemoveBtn = min_length < 1 || data?.length > min_length

    return(
        <div className="relative w-full mb-3">

            <label
                className="block uppercase text-blueGray-600 text-sm font-bold mt-4 mb-2"
            >
                {title}
            </label>


                {data?.map((inputs, commonIndex) => {
                    return <div className="flex items-center mb-4" key={commonIndex}>
                        {
                            inputs?.map((input) => {
                                return (
                                    <Input
                                        {...input}
                                        key={input.id}
                                        onChangeHandler={(e, id) => onChangeHandler(e, id, parentName, commonIndex)}
                                        errors={errors}
                                        setErrors={setErrors}
                                        className={`${input.type === inputTypes.checkbox ? "flex-1" : "flex-2"} mr-4`}
                                    />
                                )
                            })
                        }

                        {showRemoveBtn && (
                            <Button
                                onClick={() => removeInputsGroup(parentName, commonIndex)}
                                className="mb-3"
                                fullWidth={false}
                            >
                                -
                            </Button>
                        )}

                    </div>
                })}

            {showAddBtn && (
                <Button
                    onClick={() => addInputsGroup(parentName)}
                    fullWidth={false}
                >
                    +
                </Button>
            )}

        </div>
    )
}

export default memo(MultipleInput);
