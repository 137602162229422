import React, { useState, useEffect } from "react";
import Modal from "components/Modals/Modal";
import invoiceModel from "store/invoiceModel";
import userModel from "store/userModel";
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';
import {useHistory} from "react-router";

// components

function CardInvoicesList(props) {
    const limit = props.limit;
    const history = useHistory();

    const invoices = invoiceModel.invoices;

    useEffect(() => {
        invoiceModel.loadInvoices(limit);
    }, [limit, invoices.length, userModel.invoices]);

    function renderInvoice(item) {
        return (
            <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    #{item.id}
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(item.date_create).toLocaleDateString()}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {new Date(item.date_pay).toLocaleDateString()}
                </td>
                {
                    item.status === 'new' ?
                    (
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <i className="fas fa-circle text-lightBlue-500 mr-4"></i>
                            Новый
                        </td>
                    ) : item.status === 'expired' ?
                    (
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <i className="fas fa-circle text-red-500 mr-4"></i>
                            Просрочен
                        </td>
                    ) : item.status === 'payed' ?
                    (
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <i className="fas fa-check-circle text-emerald-500 mr-4"></i>
                            Оплачен
                        </td>
                    ) : null
                }
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item.amount}р.
                </td>
            </tr>
        )
    }

    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-2 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-base text-blueGray-700">
                                <i className="fas fa-file-invoice-dollar mr-3" ></i>
                                Счета
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                        <tr>
                            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Счет
                            </th>
                            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Создан
                            </th>
                            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Срок оплаты
                            </th>
                            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Статус
                            </th>
                            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Сумма
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            {invoices.map(renderInvoice)}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default observer(CardInvoicesList)
