import React from "react";

import CardNetworkNodes from "../../components/Cards/CardNetworkNodes";
import CardNetworkDetail from "../../components/Cards/CardNetworkDetail";
import CardNetworkContracts from "../../components/Cards/CardNetworkContracts";
import {useHistory, useParams} from "react-router";

export default function NetworkDetail(props) {
    const history = useHistory()
    const { networkId } = useParams()


    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
                    <CardNetworkDetail networkId={networkId} />
                    <CardNetworkContracts history={history} networkId={networkId} />
                </div>
                <div className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
                    <CardNetworkNodes history={history} networkId={networkId} />
                </div>
            </div>
        </>
    );
}
