import React, { useState, useEffect } from "react";
import Modal from "components/Modals/Modal";
import contractModel from "store/contractModel";
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';
import {useHistory} from "react-router";


function CardContractList(props) {
    const history = useHistory();

    const clientContracts = contractModel.clientContracts;
    const contractColumn = contractModel.contractColumn;

    useEffect(() => {
        contractModel.loadClientContracts();
    }, [clientContracts.length]);

    function selectItemAndRedirect(item) {
        history.push(`/admin/networks/detail/${item.network_id}/`);
    }

    function contractItem(item) {
        return (
            <tr
                key={item.id}
                className='node-item'
                onClick={() => selectItemAndRedirect(item)}
            >
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {item.contract_address}
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item.title}
                </td>
                {item.is_deployed ?
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <i className="fas fa-check-circle text-emerald-500 mr-4"></i>
                        В сети
                    </td>
                    :
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <i className="fas fa-upload text-lightBlue-500 mr-4"></i>
                        Загрузить
                    </td>
                }
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {item.network}
                </th>
            </tr>
        )
    }


    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-2 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-base text-blueGray-700">
                                <i className="fas fa-database mr-3" ></i>
                                Контракты
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                {
                                contractColumn.map(title => (
                                    <th
                                        key={title}
                                        className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                        {title}
                                    </th>
                                ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {clientContracts.map(item => contractItem(item))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default observer(CardContractList);
