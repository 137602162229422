import { makeObservable, observable, action } from "mobx"
import API from 'api';


class InvoiceModel {
    invoices = [];

    constructor() {
        makeObservable(this, {
            invoices: observable,

            loadInvoices: action,
        });
    }

    loadInvoices(limit) {
        return new Promise((resolve, reject) => {
            const search = new URLSearchParams();
            if (limit) {
                search.append('limit', limit);
            }
            API.get('/invoice/list/?' + search.toString())
            .then(res => {
                const list = res.data;
                this.invoices = list;

                resolve(list);
            })
            .catch(reject)
        });
    }
}


const invoiceModel = new InvoiceModel();

export default invoiceModel;
