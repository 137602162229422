import React from "react";

import CardNodeList from "components/Cards/CardNodeList.js";
import {useHistory} from "react-router";

export default function Nodes(props) {
    const history = useHistory()

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
            <CardNodeList history={history}/>
        </div>
      </div>
    </>
  );
}
