import { makeObservable, observable, action } from "mobx"
import { saveAs } from 'file-saver';
import API from 'api';

class NodeModel {
    nodes = [];
    nodesColumn = ['Узел', 'Сеть', 'Статус'];

    detail = {};
    detailStats = [];

    constructor() {
        makeObservable(this, {
            nodes: observable,
            nodesColumn: observable,
            detail: observable,
            detailStats: observable,

            create: action,
            turnStatus: action,
            download: action,
            loadNodeList: action,
            loadDetail: action,
            loadDetailStats: action,
        });
    }

    get onlineNode() {
        return this.nodes.find(_ => _.is_online && _.is_active);
    }

    create(networkId, locationId, slug) {
        return new Promise(resolve => {
            API.post(`/network/node/create/?location=${locationId}`, { network: networkId, slug })
            .then(response => {
                const {data} = response;
                this.nodes.push(data);
                resolve(data);
            });
        })
    }

    turnStatus(nodeId, is_active) {
        return new Promise((resolve, reject) => {
            API.put(`/network/node/set/status/${nodeId}/`, { is_active })
            .then(res => {
                resolve(res.data);
            })
            .catch(reject)
        });
    }

    download(node_id) {
        return new Promise((resolve, reject) => {
            const search = new URLSearchParams();
            search.append('node_id', node_id);

            API.get(`/network/node/download/?${search.toString()}`, { responseType: "blob" })
            .then(res => {
                saveAs(res.data);
                resolve(res.data);
            })
            .catch(reject)
        });
    }

    loadNodeList() {
        return new Promise((resolve, reject) => {
            API.get(`/network/node/list/`)
            .then(res => {
                const list = res.data;
                this.nodes = list;
                resolve(list);
            })
            .catch(reject)
        });
    }

    loadDetail(nodeId) {
        return new Promise((resolve, reject) => {
            API.get(`/network/node/detail/${nodeId}/`)
            .then(res => {
                this.detail = res.data;
                resolve(res.data);
            })
            .catch(reject)
        });
    }

    loadDetailStats(nodeId) {
        return new Promise((resolve, reject) => {
            API.get(`/network/node/detail/${nodeId}/stats/`)
            .then(res => {
                this.detailStats = res.data;
                resolve(res.data);
            })
            .catch(reject)
        });
    }
}

const nodeModel = new NodeModel();

export default nodeModel;