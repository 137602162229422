import React, { useEffect, useState } from "react";
import Modal from "components/Modals/Modal";
import toast from 'react-hot-toast';
import locationModel from "store/locationModel";
import networkModel from "store/networkModel";
import nodeModel from "store/nodeModel";
import { observer } from 'mobx-react';
import {useHistory} from "react-router";

// components

function CardNetworkNodes(props) {
    const [showModal, setShowModal] = useState(false);
    const [location, setLocation] = useState('');
    const item = networkModel?.detail;

    const history = useHistory()

    useEffect(() => {
        locationModel.loadLocations();
    }, []);

    useEffect(() => {
        setLocation(locationModel.firstLocation?.id);
    }, [locationModel.firstLocation?.id]);

    function optionsTemplate(items) {
        return items.map((item, index) => (<option key={item.id} value={item.id} selected={index === 0 ? true: false}>{item.title}</option>));
    }

    function handleCreateNode() {
        nodeModel.create(item?.id, location)
        .then(item => {
            setShowModal(false);
            toast.success(`Узел добавлен!`);
        });
    }

    function nodesItem(item, index) {
        function turnNodeHandler() {
            nodeModel.turnStatus(item.id, !item.is_active)
            .then(data => {
                item.is_active = data.is_active;
            })
        }

        function redirectToNode() {
            history.push(`/admin/nodes/detail/${item.id}`);
        }

        function openExplorer() {
            console.log('item', item);
            window.open(item.explorer_url);
        }

        return (
            <tr key={item.id}
                className='node-item'
            >
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {index + 1}
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <p onClick={() => redirectToNode()}>{item.id}</p>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <span className="turn-node-btn" onClick={() => nodeModel.download(item.id)}>Скачать</span>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <i className="fas fa-globe mr-4 turn-node-btn" title={'Эксплорер'} alt={'Эксплорер'} onClick={openExplorer} />
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {item.is_active ?
                        <i className="fas fa-stop text-red-500 mr-4 turn-node-btn" title={'Выключить'} alt={'Выключить'} onClick={turnNodeHandler} /> :
                        <i className="fas fa-play text-emerald-500 mr-4 turn-node-btn" title={'Включить'} alt={'Включить'} onClick={turnNodeHandler} />
                    }
                </td>
            </tr>
        )
    }

    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-2 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-base text-blueGray-700">
                                <i className="fas fa-database mr-3" ></i>
                                Узлы сети
                            </h3>
                        </div>
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                            <button
                                className="bg-emerald-500 text-white active:bg-emerald-500 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowModal(true)}
                            >
                                Добавить узел
                            </button>
                        </div>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <tbody>
                            {nodeModel.nodes?.map((item, index) => nodesItem(item, index))}
                        </tbody>
                    </table>
                </div>
            </div>

            {showModal ?
                <Modal
                    onRequestClose={() => setShowModal(false)}
                    closeOnBackgroundClick={false}
                >
                    <h3 className="modal__title">Создание узла</h3>
                    <div className='row'>
                        <div className='col-12'>
                        <label for="node-location" className="form-label">Локация</label>
                        <div className="input-group mb-3">
                            <select defaultValue={location} value={location} className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full" id="node-location" value={location} onChange={(e) => setLocation(e.target.value)}>
                            {
                                optionsTemplate(locationModel.locations)
                            }
                            </select>
                        </div>
                        </div>

                    </div>

                    <div className='flex justify-center mt-5'>
                        <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                        >
                        Закрыть
                        </button>
                        <button
                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => handleCreateNode()}
                        >
                        Создать
                        </button>
                    </div>
                </Modal>
                : null
            }
        </>
    );
}

export default observer(CardNetworkNodes);
