import React, { useEffect } from "react";

// components

export default function Modal({onRequestClose, children, closeOnBackgroundClick=true, style}) {
  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        onRequestClose();
      }
    }

    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });

  return (
    <>
    <div className="modal__backdrop">
      <div className="modal__container" style={{minHeight: '300px'}}>
        {children}
      </div>
    </div>
    <div 
      style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 10 }}
			onClick={ closeOnBackgroundClick ? onRequestClose : null } />
    </>
  );
}
