import React, {memo, useEffect, createElement, useCallback} from "react";
import {inputTypes} from "../../constants";

const Input = ({
    id,
   title,
   value,
   onChangeHandler,
   max_length,
   required,
   name,
   errors,
   setErrors,
   className,
   type
}) => {
    const isMaxLengthError = max_length > 0 && value.length > max_length;
    const isRequiredError = required && value.length < 1;

    useEffect(() => {
        const error = errors.find((data) => data.id === id);

        if(error?.maxLength === isMaxLengthError && error?.required === isRequiredError) return;

        setErrors(errors.map((error) => {
            if(error.id === id) {
                error.required = isRequiredError;
                error.maxLength = isMaxLengthError;
            }
            return error;
        }));
    }, [value]);

    const errorMessage = (isRequiredError && "Поле является обязательным") || (isMaxLengthError && `Максимальная длина ${max_length}`);

    const getInputType = useCallback(() => {
        switch (type) {
            case inputTypes.number:
                return type;
            case inputTypes.checkbox:
                return "checkbox";
            default:
                return inputTypes.text;
        }
    }, [type])

    const isCheckbox = type === inputTypes.checkbox;
    const widthFullClassName = isCheckbox ? "" : "w-full";

    return(
        <div className={`relative w-full mb-3 ${widthFullClassName} ${className}`}>

            <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
                {title}
            </label>

            {createElement(
                type === inputTypes.textarea ? "textarea" : "input",
                {
                        type: getInputType(),
                        className:`${widthFullClassName} border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150`,
                        value,
                        ...(isCheckbox && { checked: value }),
                        onChange: (e) => onChangeHandler(e, id),
                    }
            )}


                <label
                    className="block text-red-600 text-xs font-bold mt-2 h-4"
                    htmlFor="grid-password"
                >
                    {errorMessage}
                </label>


        </div>
    )
}

export default memo(Input);
