import {useCallback, useState} from "react";


const useResizeTwoBlocks = (mainBlock) => {
    const [borderPosition, setBorderPosition] = useState(null)

    const onMouseMoveHandler = useCallback((e) => {
        const { clientX } = e;
        const { left } = mainBlock.current.getBoundingClientRect()

        setBorderPosition(clientX - left);
    }, [mainBlock, setBorderPosition]);

    const onMouseUpHandler = useCallback(() => {
        mainBlock.current?.removeEventListener("mousemove", onMouseMoveHandler);

        window.removeEventListener('mouseup', onMouseUpHandler);
    }, [onMouseMoveHandler, mainBlock]);

    const onMouseDownHandler = useCallback(() => {
        mainBlock.current?.addEventListener("mousemove", onMouseMoveHandler);

        window.addEventListener('mouseup', onMouseUpHandler);
    }, [mainBlock, onMouseMoveHandler, onMouseUpHandler]);

    const getCalcSideWidth = (percent = 50) => `calc(${percent}% - 10px)`

    const isMovedBorder = mainBlock.current && borderPosition
    const borderPositionPercent = isMovedBorder && (borderPosition * 100 / mainBlock.current.offsetWidth)
    const leftSideWidth =  getCalcSideWidth(isMovedBorder ? borderPositionPercent : undefined);
    const rightSideWidth = getCalcSideWidth(isMovedBorder ? 100 - borderPositionPercent : undefined);

    return {
        leftSideWidth,
        rightSideWidth,
        onMouseDownHandler,
        onMouseUpHandler,
        borderPosition
    }
}

export default useResizeTwoBlocks;
