import { makeObservable, observable, action, runInAction } from "mobx"
import API from 'api';

class LocationModel {
    locations = [];

    constructor() {
        makeObservable(this, {
            locations: observable,

            loadLocations: action,
        });
    }

    get firstLocation() {
        return this.locations[0];
    }

    loadLocations() {
        return new Promise(resolve => {
            API.get('/network/server/location/list/')
            .then(response => {
                const {data} = response;
                this.locations = data;
                resolve(data);
            });
        })
    }
}

const locationModel = new LocationModel();

export default locationModel;