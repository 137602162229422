import React, { useEffect, useState } from "react";
import networkModel from "store/networkModel";
import nodeModel from "store/nodeModel";
import { observer } from 'mobx-react';
import toast from 'react-hot-toast';

// components

function CardNetworkDetail(props) {
    console.log('CardNetworkDetail', props);
    const networkId = props.networkId;

    const item = networkModel?.detail;
    const [isEditTitle, setIsEditTitle] = useState(false);
    const [newTitle, setNewTitle] = useState('');

    useEffect(() => {
        networkModel.loadDetail(networkId);
    }, [networkId]);

    useEffect(() => {
        setNewTitle(item?.title);
    }, [item?.title]);

    useEffect(() => {
        networkModel.loadDetail(networkId);
    }, [nodeModel.nodes.length]);

    function handleUpdateTitle() {
        if (item?.title === newTitle) {
            return setIsEditTitle(false);
        }
        
        networkModel.updateTitle(item?.id, newTitle)
        .then(netData => {
            toast.success(`Сеть обновлена!`);
            networkModel.detail = {
                ...networkModel.detail,
                ...netData,
            };
            setIsEditTitle(false);
        })
    }

    function renderItemTitle() {
        if (!isEditTitle) {
            return (
                <h3 className="font-semibold text-base text-blueGray-700">
                    <i className="fas fa-network-wired mr-3" ></i>
                    {item?.title} 
                    <i 
                        className="fas fa-pen text-blueGray-300 ml-3 edit-item-title"
                        onClick={() => setIsEditTitle(!isEditTitle)}
                    ></i>
                </h3>
            )
        }

        return (
            <h3 className="font-semibold text-base text-blueGray-700">
                <i className="fas fa-network-wired mr-3"></i>

                <input 
                    type='text' 
                    value={newTitle} 
                    onChange={(e) => setNewTitle(e.target.value)}
                ></input>
                
                <i 
                    className="fas fa-pen text-blueGray-300 ml-3 edit-item-title"
                    onClick={() => handleUpdateTitle()}
                ></i>
            </h3>
        )
    }

    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-2 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-base text-blueGray-700">
                                {renderItemTitle()}
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="block w-full overflow-x-auto">
                    {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <tbody>
                        <tr>
                            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                Консенсус
                            </th>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {item?.consensus_title}
                            </td>
                        </tr>
                        <tr>
                            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                Шифрование
                            </th>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {item?.algorithm_title}
                            </td>
                        </tr>
                        <tr>
                            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                Узлов в сети
                            </th>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {item?.nodes?.length}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default observer(CardNetworkDetail);
